@import '~antd/dist/antd.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
/* @import '~react-date-picker/dist/DatePicker.css'; */
/* @import '~react-calendar/dist/Calendar.css'; */

body {
  margin: 0;
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Kanit', sans-serif;
}

.no-bounce {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 80px;
}

/* Schedule */
.my_custom_day_header {
  background-color: white !important;
  font-size: 16px;
}

.my_custom_day_body {
  background-image: none !important;
  font-family: 'Kanit', sans-serif;
  /* max-width: 10rem !important; */
}
/* Schedule */

/* FullScreen */
.fullscreen {
  background: #fff;
}

.fullscreen-enabled .fullscreen {
  background: #fff;
}
/* FullScreen */

.ant-picker {
  width: 100%;
  padding: 8px 12px;
  border-radius: 5px;
}

.ant-picker:hover {
  border-width: 1px;
  border-color: #8bc53e;
}
.ant-picker-focused {
  border: 1px solid #8bc53e;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.ant-picker.ant-picker-disabled {
  opacity: 0.8;
  cursor: not-allowed;
  border-width: 0.1px;
  background-color: transparent;
}

.rowClassName1 td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.rowClassName1 td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 10px;
}

/* react big calendar */

.rbc-allday-cell {
  display: none;
}
.rbc-time-view .rbc-header {
  border-bottom: none;
}

/* .rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot > .rbc-label {
  text-align: right;
  font-family: 'Times New Roman', Times, serif;
} */

.grid-container {
  display: block; /* This is a (hacky) way to make the .grid element size to fit its content */
  overflow: auto;
  /* height: 900px; */
  /* width: 600px; */
}
.grid {
  display: flex;
  flex-wrap: nowrap;
}
.grid-col {
  /* width: 150px; */
  min-width: 15px;
}

.grid-row {
  width: 75vw;
}

.grid-item--header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 4 !important;
}

.grid-col--fixed-left {
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
  z-index: 3 !important;
}
/* .grid-col--fixed-right {
  position: sticky;
  right: 0;
  z-index: 1;
  background: white;
} */

.grid-item {
  height: 50px;
  border: 1px solid gray;
}
