.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-loader {
  width: 240px;
  height: 44px;
  border-radius: 40px;
  color: #8cc63e;
  border: 4px solid;
  position: relative;
}
.custom-loader::before {
  content: '';
  position: absolute;
  margin: 4px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: #8cc63e;
  animation: p6 2s infinite;
}

.custom-loader-normal {
  width: 120px;
  height: 22px;
  border-radius: 20px;
  color: #8cc63e;
  border: 2px solid;
  position: relative;
}
.custom-loader-normal::before {
  content: '';
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: #8cc63e;
  animation: p6 2s infinite;
}

.custom-loader-white {
  width: 120px;
  height: 22px;
  border-radius: 20px;
  color: #ffffff;
  border: 2px solid;
  position: relative;
}
.custom-loader-white::before {
  content: '';
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: #ffffff;
  animation: p6 2s infinite;
}
@keyframes p6 {
  100% {
    inset: 0;
  }
}
